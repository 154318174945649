//body {
//  margin: 0;
//  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//    sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//    monospace;
//}

.rounded-input {
  input {
    border-radius: 50px;
    font-size: 12px;
  }
}

.number-input {
  .chakra-numberinput__field {
    padding: 0 16px !important;
  }
}
