.notification-form {
  .react-datepicker__input-container {
    border: unset;
    height: 20px;
    padding: unset;

    input {
      height: 20px
    }
  }

  .chakra-form-control {
    input:focus {
      box-shadow: unset;
    }

    ;

    textarea:focus {
      box-shadow: unset;
    }
  }
}