.intl-tel-input {
  :focus-visible {
    border: 1px solid green;
    box-shadow: none;
    outline: none !important;
  }

  .selected-flag {
    border-radius: 100px !important;
  }
}
